<template>
    <form @submit.prevent="submit">

        <div class="card-body">

            <div v-if="success" class="alert alert-success" role="alert">
                <span>
                    {{ message }}
                </span>
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="firstname" required>{{ translations['base_application::forms.create_user_label_name_first'] }}</label>
                        <input type="text" class="form-control" id="firstname" value="user.firstname" name="firstname" v-model="fields.firstname">
                        <div v-if="errors && errors.firstname" class="text-danger">{{ errors.firstname[0] }}</div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="middlename">{{ translations['base_application::forms.create_user_label_name_middle'] }}</label>
                        <input type="text" class="form-control" id="middlename" value="user.middlename" name="middlename" v-model="fields.middlename">
                        <div v-if="errors && errors.middlename" class="text-danger">{{ errors.middlename[0] }}</div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="lastname" required>{{ translations['base_application::forms.create_user_label_name_last'] }}</label>
                        <input type="text" class="form-control" id="lastname" value="user.lastname" name="lastname" v-model="fields.lastname">
                        <div v-if="errors && errors.lastname" class="text-danger">{{ errors.lastname[0] }}</div>
                    </div>
                </div>
            </div>

            <!-- If the user is pending then dont show the existing email they can see that user info page -->
            <div class="form-group" v-if="user.pending_user_email == null">
                <label for="email" required>{{ translations['base_application::forms.create_user_label_email'] }}</label>
                <input type="email" class="form-control" id="email" value="user.email" name="email" v-model="fields.email">
                <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
            </div>

            <!-- If the user is pending show the pending email -->
            <div class="form-group" v-if="user.pending_user_email !== null">
                <label for="email" required>{{ translations['base_application::forms.create_user_label_email'] }} ({{ translations['base_application::forms.pending_verification'] }})</label>
                <input type="text" class="form-control" id="email" v-model="user.pending_user_email.email">
                <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                <p class="text-muted">{{ translations['base_application::forms.current_verified'] }}: {{user.email}}</p>
            </div>

            <div class="form-group">
                <label for="mobile_phone">{{ translations['base_application::forms.manage_account_label_phone'] }}</label>
                <input type="text" class="form-control format-phone" id="mobile_phone" value="user.mobile_phone" name="mobile_phone" v-model="user.mobile_phone">
                <div v-if="errors && errors.mobile_phone" class="text-danger">{{ errors.mobile_phone[0] }}</div>
            </div>

            <!--                <div class="form-group">-->
            <!--                    <label for="role">{{ translations['base_application::forms.create_user_label_role'] }}</label>-->
            <!--                    <select class="form-select" id="role" v-model="fields.role">-->
            <!--                        <option value="">Select Role</option>-->
            <!--                        <option v-for="(role, key) in roles" v-bind:value="key" :selected="fields.role === key">{{ role }}</option>-->
            <!--                    </select>-->
            <!--                    <div v-if="errors && errors.role" class="text-danger">{{ errors.role[0] }}</div>-->
            <!--                </div>-->
            <!--                <div id="company" class="form-group" v-if="roles_requiring_company.includes(fields.role)">-->
            <!--                    <label for="company">{{ translations['base_application::forms.create_user_label_company'] }}</label>-->
            <!--                    <select class="form-select" id="company_slug" v-model="fields.company_slug" @change="loadBranches()">-->
            <!--                        <option value="">{{ translations['base_application::forms.create_user_select_company'] }}</option>-->
            <!--                        <option v-for="company in companies" v-bind:value="company.slug">{{ company.name }}</option>-->
            <!--                    </select>-->
            <!--                    <div v-if="errors && errors.company_slug" class="text-danger">{{ errors.company_slug[0] }}</div>-->
            <!--                </div>-->
            <!--                <div id="branch" class="form-group" v-if="roles_requiring_branch.includes(fields.role)">-->
            <!--                    <label for="branch">{{ translations['base_application::forms.create_user_label_branch'] }}</label>-->
            <!--                    <select class="form-select" id="branch_slug" v-model="fields.branch_slug">-->
            <!--                        <option value="">{{ translations['base_application::forms.create_user_select_branch'] }}</option>-->
            <!--                        <option v-model="branches" v-for="branch in branches" v-bind:value="branch.slug">{{ branch.name }}</option>-->
            <!--                    </select>-->
            <!--                    <div v-if="errors && errors.branch_slug" class="text-danger">{{ errors.branch_slug[0] }}</div>-->
            <!--                </div>-->
            <div class="form-group" v-if="can_manage_tracking">
                <label for="name">{{ translations['base_application::forms.create_user_label_tracking_id'] }}</label>
                <input type="text" class="form-control" id="tracking_id" v-model="fields.tracking_id">
                <div v-if="errors && errors.tracking_id" class="text-danger">{{ errors.tracking_id[0] }}</div>
            </div>

            <div class="form-group" v-if="loggedInUser.slug != user.slug">
                <input name="disabled" id="disabled" type="checkbox" v-model="fields.disabled" :checked="fields.disabled">
                <label for="disabled">{{ translations['base_application::forms.disabled'] }}</label>
            </div>

        </div>
        <div class="card-footer">
            <button type="submit" class="btn btn-primary" :disabled="!loaded">
                <span v-if="!loaded" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ translations['base_application::forms.button_submit'] }}
            </button>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            loaded: true,
            success: false,
            errors: {},
            fields: {},
            message: '',
            loggedInUser: {
                slug: null,
            },
        }
    },
    props: [
        'user',
        'branches',
        'companies',
        'roles',
        'roles_requiring_company',
        'roles_requiring_branch',
        'can_manage_tracking',
        'translations'
    ],
    mounted() {
        this.fields = this.user;
        this.fields.slug = this.user.slug;
        this.fetchUserData();
    },
    methods: {
        submit() {
            if (this.loaded) {
                this.loaded = false;
                this.success = false;
                this.errors = {};
                axios.post('/update-user', this.fields).then(response => {
                    this.fields = {}; // Clear input fields.
                    this.loaded = true;
                    this.success = true;
                    this.message = response.data.msg;

                    window.location.href = '/manage-users';
                }).catch(error => {
                    this.loaded = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            }
        },
        loadBranches: function () {
            if (this.roles_requiring_branch.includes(this.fields.role)) {
                axios.get('/get-branches-by-company/' + this.fields.company_slug)
                    .then(response => {
                        this.branches = response.data;
                    }).catch(error => {
                    console.log(error);
                });
            }
        },
        fetchUserData() {
            axios.get('/user-data')
                .then(response => {
                    this.loggedInUser = response.data;
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                });
        },
    }
}
</script>
