<template>
    <div>
        <form @submit.prevent="createCriteriaSet">
            <div class="card-body">
                <!-- Criteria Set Form -->
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label for="name" required>Criteria Set Name</label>
                            <input
                                type="text"
                                id="name"
                                class="form-control"
                                v-model="criteriaSet.name"
                                required
                            />
                        </div>
                    </div>
                </div>

                <!-- Criteria Configs -->
                <label for="criteria" required>Criteria</label>

                <div v-for="(config, index) in criteriaConfigs" :key="index">
                    <div class="row mb-3">
                        <!-- Attribute Selection -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <v-select
                                    v-model="config.attribute"
                                    :options="attributes"
                                    label="name"
                                    placeholder="Select an option or type to search"
                                    :clearable="false"
                                    required
                                ></v-select>
                            </div>
                        </div>

                        <!-- Rule Selection -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <select class="form-select" v-model="config.rule" required>
                                    <option value="" disabled>Select a rule</option>
                                    <option
                                        v-for="rule in getAvailableRules(config.attribute)"
                                        :key="rule.value"
                                        :value="rule.value"
                                    >
                                        {{ rule.text }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <!-- Value Input -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <input type="text" class="form-control" v-model="config.value" required />
                            </div>
                        </div>

                        <!-- Remove Criteria Button -->
                        <div class="col-md-1">
                            <button
                                type="button"
                                class="btn btn-danger btn-block"
                                @click="removeCriteriaConfig(index)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Add Criteria Button -->
                <div class="row">
                    <div class="col">
                        <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">
                            Add Additional Criteria
                        </button>
                    </div>
                </div>
            </div>

            <!-- Form Footer -->
            <div class="card-footer">
                <div class="row">
                    <div class="col">
                        <button type="submit" class="btn btn-primary">Save Criteria Set</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    components: { vSelect },
    data() {
        return {
            criteriaSet: {
                name: ''
            },
            criteriaConfigs: [
                {
                    attribute: null,
                    rule: '',
                    value: ''
                }
            ],
            attributes: [],
            allRules: [
                { value: '==', text: 'Equals' },
                { value: '!=', text: 'Does Not Equal' },
                { value: '>', text: 'Greater Than' },
                { value: '<', text: 'Less Than' },
                { value: '>=', text: 'Greater Than or Equal To' },
                { value: '<=', text: 'Less Than or Equal To' },
                { value: 'contains', text: 'Contains' }
            ]
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        createCriteriaSet() {
            // Prepare the payload by extracting necessary fields
            const payload = {
                criteria_set: this.criteriaSet,
                criteria_configs: this.criteriaConfigs.map((config) => ({
                    attribute: config.attribute.name, // Use attribute name or ID as needed
                    rule: config.rule,
                    value: config.value
                }))
            };

            // Make an API request to create the CriteriaSet and CriteriaConfigs
            axios
                .post('/ps/criteria_set', payload)
                .then((response) => {
                    // Handle the response here, if needed
                    window.location.href = '/ps/criteria_sets';
                })
                .catch((error) => {
                    // Handle any errors here
                    console.error('Error creating Criteria Set and Configurations:', error);
                });
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                attribute: null,
                rule: '',
                value: ''
            });
        },
        removeCriteriaConfig(index) {
            this.criteriaConfigs.splice(index, 1);
        },
        fetchAttributes() {
            axios
                .get('/ps/get-attributes-for-user')
                .then((response) => {
                    this.attributes = response.data;
                })
                .catch((error) => {
                    console.error('Error fetching attributes:', error);
                });
        },
        getAvailableRules(attribute) {
            console.log(attribute);
            if (!attribute || !attribute.type) {
                // Default rules when no attribute is selected or data_type is missing
                return this.allRules.filter(
                    (op) => !['>', '<', '>=', '<='].includes(op.value)
                );
            }

            if (attribute.type === 'int' || attribute.type === 'decimal') {
                // All rules for numeric data types
                return this.allRules;
            }

            // Exclude numeric operators for other data types
            return this.allRules.filter(
                (op) => !['>', '<', '>=', '<='].includes(op.value)
            );
        }
    },
    watch: {
        // Watch for changes in each config's attribute and reset the rule if necessary
        criteriaConfigs: {
            handler(newConfigs, oldConfigs) {
                newConfigs.forEach((config, index) => {
                    const oldConfig = oldConfigs[index] || {};
                    if (config.attribute !== oldConfig.attribute) {
                        // If the attribute has changed, reset the rule if it's no longer valid
                        const availableRules = this.getAvailableRules(config.attribute);
                        if (!availableRules.find((rule) => rule.value === config.rule)) {
                            config.rule = '';
                        }
                    }
                });
            },
            deep: true
        }
    }
};
</script>
