<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-6">
                <label for="allDaysLimit" class="form-label">Daily Limits</label>
                <div class="input-group">
                    <input
                        type="number"
                        id="allDaysLimit"
                        v-model.number="allDaysLimit"
                        min="0"
                        @input="setAllDailyLimits"
                        class="form-control"
                        placeholder="Unlimited"
                        style="max-width:200px;"
                    >
                    <button @click="applyAllDailyLimits" class="btn btn-primary">Apply to all days</button>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3 mb-3">
            <div v-for="day in daysOfWeek" :key="day" class="col">
                <div class="card mb-1">
                    <div class="card-body">
                        <div class="form-check mb-2">
                            <input type="checkbox" :id="day" v-model="schedule.days[day].enabled" class="form-check-input">
                            <label :for="day" class="form-check-label">{{ day }}</label>
                        </div>
                        <div class="input-group input-group-sm">
                            <input
                                type="number"
                                :id="`${day}-limit`"
                                v-model.number="schedule.days[day].limit"
                                min="0"
                                :disabled="!schedule.days[day].enabled"
                                class="form-control"
                                placeholder="Unlimited"
                                style="max-width:200px;"
                            >
                            <span class="input-group-text">Daily limit</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="float-start pe-3" style="max-width:200px;">
                <label for="perWeek" class="form-label">Weekly Limit</label>
                <input type="number" id="perWeek" v-model.number="schedule.limits.perWeek" min="0" class="form-control" placeholder="Unlimited">
            </div>
            <div class="float-start" style="max-width:200px;">
                <label for="perMonth" class="form-label">Monthly Limit</label>
                <input type="number" id="perMonth" v-model.number="schedule.limits.perMonth" min="0" class="form-control" placeholder="Unlimited">
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch } from 'vue'

export default {
    name: 'AdvancedScheduler',
    emits: ['update:scheduleData'],
    setup(props, { emit }) {
        const daysOfWeek = ref(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'])
        const allDaysLimit = ref(null)
        const schedule = reactive({
            days: {
                Monday: { enabled: true, limit: null },
                Tuesday: { enabled: true, limit: null },
                Wednesday: { enabled: true, limit: null },
                Thursday: { enabled: true, limit: null },
                Friday: { enabled: true, limit: null },
                Saturday: { enabled: true, limit: null },
                Sunday: { enabled: true, limit: null }
            },
            limits: {
                perWeek: null,
                perMonth: null
            }
        })

        const setAllDailyLimits = () => {
            daysOfWeek.value.forEach(day => {
                schedule.days[day].limit = allDaysLimit.value
            })
        }

        const applyAllDailyLimits = () => {
            daysOfWeek.value.forEach(day => {
                schedule.days[day].enabled = true
                schedule.days[day].limit = allDaysLimit.value
            })
        }

        watch([daysOfWeek, allDaysLimit, schedule], () => {
            emit('update:scheduleData', {
                daysOfWeek: daysOfWeek.value,
                allDaysLimit: allDaysLimit.value,
                schedule: schedule
            })
        }, { deep: true })

        return {
            daysOfWeek,
            allDaysLimit,
            schedule,
            setAllDailyLimits,
            applyAllDailyLimits
        }
    }
}
</script>
