<template>
    <div class="row">
        <div class="col-lg-8">
            <div class="program-creation">
                <div v-if="Object.keys(validationErrors).length > 0" class="alert alert-danger mt-3">
                    <ul>
                        <li v-for="(errors, field) in validationErrors" :key="field">
                            <strong>{{ field }}:</strong>
                            <ul>
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <!-- Step 1: Company Selection (for resellers) -->
                <div v-if="currentStep === 1 && (companies.length > 1)" class="mb-4">
                    <p>As a reseller, we first need to know which company you are working with.</p>

                    <div class="row">
                        <div class="col-sm-9 col-xl-6">
                            <label class="form-label" required>Company</label>
                            <v-select
                                v-model="selectedCompany"
                                :options="companies"
                                label="name"
                                :reduce="company => company.slug"
                                placeholder="Select a company"
                                :clearable="false"
                                required
                            ></v-select>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button @click="nextStep" :disabled="!selectedCompany" class="btn btn-primary">Continue</button>
                    </div>
                </div>

                <!-- Step 2: Consumer Source -->
                <div v-if="currentStep === 2" class="mb-4">
                    <p>For which population of consumers are you looking for data?</p>

                    <label required>Consumer Source</label>
                    <div class="mb-3">
                        <div class="form-check">
                            <input type="radio" id="prescreen" value="prescreen" v-model="consumerSource" class="form-check-input">
                            <label for="prescreen" class="form-check-label">Prescreen Universe <span class="small">(approx. 200 million available consumers)</span></label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="customList" value="customList" v-model="consumerSource" class="form-check-input">
                            <label for="customList" class="form-check-label">My List of Leads</label>
                        </div>
                    </div>
                    <div v-if="consumerSource === 'customList'" class="mb-3">
                        <label>Leads</label>
                        <div class="mb-3">
                            <div class="form-check">
                                <input type="radio" id="existingList" value="existingList" v-model="customListType" class="form-check-input">
                                <label for="existingList" class="form-check-label">Use Previously Uploaded Leads</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" id="uploadNewList" value="uploadNewList" v-model="customListType" class="form-check-input">
                                <label for="uploadNewList" class="form-check-label">Upload New Leads</label>
                            </div>
                        </div>
                        <div v-if="consumerSource === 'customList' && customListType === 'existingList'" class="mb-3">
                            <label class="form-label">Inclusion Tags</label>
                            <TagSelector
                                v-model="selectedTags"
                                :existing-tags="availableInclusionTags"
                                :disabled-tags="suppressedTags"
                            />
                            <div v-if="selectedTags.length > 1" class="mt-3">
                                <label class="form-label">Tag Inclusion Method</label>
                                <div class="form-check">
                                    <input type="radio" id="mergeTags" value="merge" v-model="tagCombinationMethod" class="form-check-input">
                                    <label for="mergeTags" class="form-check-label">Merge (must include any)</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" id="intersectTags" value="intersect" v-model="tagCombinationMethod" class="form-check-input">
                                    <label for="intersectTags" class="form-check-label">Intersect (must include all)</label>
                                </div>
                            </div>
                            <div class="mt-3">
                                <label class="form-label">Suppression Tags</label>
                                <TagSelector
                                    v-model="suppressedTags"
                                    :existing-tags="availableSuppressionTags"
                                    :disabled-tags="selectedTags"
                                />
                            </div>
                        </div>
                        <div v-if="customListType === 'uploadNewList'" class="mb-3">
                            <label class="form-label" required>Tags</label>
                            <TagSelector
                                v-model="selectedTags"
                                :existing-tags="customListNames"
                                :show-add="true"
                            />

                            <label class="form-label" required>CSV File</label>
                            <input type="file" @change="handleFileUpload" accept=".csv" class="form-control mb-2">

                            <div class="small mb-2">
                                The CSV file must match the format provided in the sample file, including headers.
                                <a href="/storage/downloads/sample.csv" target="_blank">Download Sample File</a>
                                <br>
                                If providing a tracking_id, it must be unique for each Lead and cannot be reused for anyone but this Lead.
                            </div>

                            <button @click="saveNewList" class="btn btn-secondary mb-2" :disabled="selectedTags.length === 0 || !fileToUpload">Upload New Leads</button>
                        </div>
                    </div>

                    <button v-if="companies.length > 1" @click="previousStep" class="btn btn-secondary me-2">Back</button>
                    <button @click="nextStep" :disabled="!isStep2Valid" class="btn btn-primary">Continue</button>
                </div>

                <!-- Step 3: Criteria Set -->
                <div v-if="currentStep === 3" class="mb-4">
                    <p>Optionally, narrow down your population based on criteria.</p>

                    <label>Filter Population</label>
                    <div class="mb-3">
                        <div class="form-check">
                            <input type="radio" id="noCriteria" value="none" v-model="criteriaSetType" class="form-check-input" :disabled="consumerSource === 'prescreen'">
                            <label for="noCriteria" class="form-check-label" :class="{ 'text-muted': consumerSource === 'prescreen' }">Don't Use Criteria Filters</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="useCriteria" value="use" v-model="criteriaSetType" class="form-check-input">
                            <label for="useCriteria" class="form-check-label">Use Criteria Filters</label>
                        </div>
                    </div>

                    <ProgramCriteriaSetComponent
                        v-if="criteriaSetType === 'use'"
                        :initial-criteria-set-slug="selectedCriteriaSet"
                        @update:criteriaSetSlug="updateSelectedCriteriaSet"
                        @criteria-set-changed="handleCriteriaSetChanged"
                        @criteria-set-saved="handleCriteriaSetSaved"
                        @criteria-set-type-changed="handleCriteriaSetTypeChange"
                    />

                    <button @click="previousStep" class="btn btn-secondary me-2">Back</button>
                    <button @click="nextStep" :disabled="!isStep3Valid" class="btn btn-primary">Continue</button>
                </div>

                <!-- Step 4: Program Options -->
                <div v-if="currentStep === 4" class="mb-4">
                    <label>Program Options</label>
                    <div class="mb-3">
                        <div class="form-check">
                            <input type="radio" id="dontUseInquiries" value="none" v-model="inquiryType" class="form-check-input">
                            <label for="dontUseInquiries" class="form-check-label">Don't Use Inquiry Data</label>
                        </div>
                        <div class="form-check" v-if="!isEditMode">
                            <input type="radio" id="useHistorical" value="historical" v-model="inquiryType" class="form-check-input">
                            <label for="useHistorical" class="form-check-label">Use Historical Inquiries</label>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="monitorNew" value="new" v-model="inquiryType" class="form-check-input">
                            <label for="monitorNew" class="form-check-label">Monitor New Inquiries</label>
                        </div>
                    </div>

                    <div v-if="inquiryType === 'historical'" class="mb-3">
                        <div class="row">
                            <div class="col-sm-9 col-xl-6">
                                <label for="historicalDays" class="form-label">Historical Inquiries Number of Days (up to 60)</label>
                                <input type="number" id="historicalDays" v-model.number="historicalDays" min="1" max="60" class="form-control" style="max-width:100px;">
                            </div>
                        </div>
                    </div>

                    <div v-if="inquiryType">
                        <div class="mb-3">
                            <label class="form-label">Delivery</label>
                            <div class="form-check" v-if="!isEditMode">
                                <input type="radio" id="oneTimeDownload" value="one-time" v-model="alertCadence" class="form-check-input" :disabled="inquiryType === 'new'">
                                <label for="oneTimeDownload" class="form-check-label" :class="{ 'text-muted': inquiryType === 'new' }">One-time Download</label>
                            </div>
                            <div class="form-check">
                                <input type="radio" id="scheduledDelivery" value="scheduled" v-model="alertCadence" class="form-check-input" :disabled="inquiryType === 'historical'">
                                <label for="scheduledDelivery" class="form-check-label" :class="{ 'text-muted': inquiryType === 'historical' }">Subscription</label>
                            </div>
                        </div>
                        <div v-if="alertCadence === 'scheduled'" class="mb-3 fst-italic">
                            <p>Data will be delivered in the form of JSON Alerts to your provided Endpoint.</p>
                        </div>
                        <div v-if="alertCadence === 'scheduled'" class="mb-3">
                            <div class="mb-3">
                                <label class="form-label" required>Endpoint</label>
                                <div class="form-check">
                                    <input type="radio" id="useExistingEndpoint" value="existing" v-model="endpointType" class="form-check-input">
                                    <label for="useExistingEndpoint" class="form-check-label">Use Existing Endpoint</label>
                                </div>
                                <div class="form-check">
                                    <input type="radio" id="createNewEndpoint" value="new" v-model="endpointType" class="form-check-input">
                                    <label for="createNewEndpoint" class="form-check-label">Create New Endpoint</label>
                                </div>
                            </div>

                            <div v-if="endpointType === 'existing'" class="mb-3">
                                <v-select
                                    v-model="selectedEndpoint"
                                    :options="endpoints"
                                    :get-option-label="endpointLabel"
                                    :reduce="endpoint => endpoint.alert_endpoint_id"
                                    placeholder="Select an existing endpoint"
                                    :clearable="false"
                                    required
                                ></v-select>
                            </div>

                            <div v-if="endpointType === 'new'" class="mb-3">
                                <label for="newEndpointName" class="form-label" required>Endpoint Name</label>
                                <input type="text" id="newEndpointName" v-model="newEndpointName" class="form-control mb-2" placeholder="New Endpoint Name" style="max-width:400px;">

                                <label for="newEndpointName" class="form-label" required>Endpoint URL</label>
                                <input type="url" id="newEndpointUrl" v-model="newEndpointUrl" class="form-control mb-2" placeholder="New Endpoint URL">

                                <button @click="saveNewEndpoint" class="btn btn-secondary mb-2" :disabled="!isNewEndpointValid">Save New Endpoint</button>
                                <div v-if="newEndpointUrl && !isValidUrl(newEndpointUrl)" class="text-danger">
                                    Please enter a valid URL (e.g., https://example.com)
                                </div>
                            </div>

                            <AdvancedScheduler @update:scheduleData="updateScheduleData" />
                        </div>
                    </div>

                    <button @click="previousStep" class="btn btn-secondary me-2">Back</button>
                    <button @click="nextStep" :disabled="!isStep4Valid" class="btn btn-primary">Continue</button>
                </div>

                <!-- Step 5: Program Details -->
                <div v-if="currentStep === 5" class="mb-4">
                    <div class="mb-3">
                        <label for="programName" class="form-label" required>Program Name</label>
                        <input type="text" id="programName" v-model="programName" class="form-control" required>
                    </div>
                    <div class="mb-3">
                        <label for="programDescription" class="form-label">Description</label>
                        <textarea id="programDescription" v-model="programDescription" class="form-control"></textarea>
                    </div>
                    <div class="mb-3">
                        <label for="trackingId" class="form-label">Tracking ID</label>
                        <input type="text" id="trackingId" v-model="trackingId" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label for="subscriberCodeSet" class="form-label" required>Subscriber Code Set</label>
                        <v-select
                            v-model="selectedSubscriberCodeSet"
                            :options="subscriberCodeSets"
                            label="name"
                            :reduce="set => set.slug"
                            placeholder="Select a subscriber code set"
                            :clearable="false"
                            required
                        ></v-select>
                    </div>
                    <div class="form-check mb-3">
                        <input type="checkbox" id="termsAgreed" v-model="termsAgreed" class="form-check-input">
                        <label for="termsAgreed" class="form-check-label">I agree to the Terms of Service</label>
                    </div>
                    <button @click="previousStep" class="btn btn-secondary me-2">Back</button>
                    <button @click="submitProgram" :disabled="!isStep5Valid" class="btn btn-primary">
                        {{ isEditMode ? 'Save Changes' : (alertCadence === 'one-time' ? 'Download File' : 'Start Subscription Program') }}
                    </button>
                </div>
            </div>
        </div>

        <div class="col text-end">
            <div v-if="consumerSource && consumerCount !== null" class="alert alert-primary float-end" style="max-width:440px;">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <label class="mb-0">Available Alerts</label>
                        <h3 class="mb-0"><span class="badge bg-primary">{{ formattedConsumerCount }}</span></h3>
                    </div>
                    <span class="small">The amount of available alerts can change after the program is created.</span>
                </div>
            </div>

            <table v-if="consumerSource || (selectedCompany && companies.length > 1)" class="table float-end table-striped" style="max-width:440px;">
                <tbody>
                    <tr v-if="selectedCompany && companies.length > 1">
                        <th>Company</th>
                        <td>
                            {{ companies.find(company => company.slug === selectedCompany)?.name }}
                        </td>
                    </tr>
                    <tr v-if="consumerSource">
                        <th>Consumer Source</th>
                        <td>
                            {{ consumerSource === 'prescreen' ? 'Prescreen Universe' : 'My Uploaded Leads' }}
                        </td>
                    </tr>

                    <tr v-if="consumerSource === 'customList' && customListType === 'existingList' && selectedTags.length > 0">
                        <th>Inclusion Tags</th>
                        <td>
                            {{ selectedTags.join(', ') }}
                        </td>
                    </tr>
                    <tr v-if="consumerSource === 'customList' && customListType === 'existingList' && tagCombinationMethod && selectedTags.length > 1">
                        <th>Tag Inclusion Method</th>
                        <td>
                            {{ tagCombinationMethod === 'merge' ? 'Merge (Union)' : 'Intersect' }}
                        </td>
                    </tr>
                    <tr v-if="consumerSource === 'customList' && customListType === 'existingList' && suppressedTags.length">
                        <th>Suppression Tags</th>
                        <td>
                            {{ suppressedTags.join(', ') }}
                        </td>
                    </tr>
                    <tr v-if="consumerSource === 'customList' && customListType === 'uploadNewList' && newListTag">
                        <th>New List Tag</th>
                        <td>
                            {{ newListTag }}
                        </td>
                    </tr>

                    <tr v-if="(criteriaSetType === 'use' || criteriaSetType === 'existing') && selectedCriteriaSet">
                        <th>Criteria Set</th>
                        <td>
                            {{ getCriteriaSetName(selectedCriteriaSet) }}
                        </td>
                    </tr>
                    <tr v-if="inquiryType && inquiryType !== 'none'">
                        <th>Inquiry Type</th>
                        <td>
                            {{ inquiryType === 'none' ? 'None' : inquiryType === 'historical' ? 'Historical' : 'New' }}
                            {{ historicalDays && inquiryType === 'historical' ? `(${historicalDays} days)` : '' }}
                        </td>
                    </tr>
                    <tr v-if="alertCadence && inquiryType">
                        <th>Delivery</th>
                        <td>
                            {{ alertCadence === 'one-time' ? 'One-time Download' : 'Subscription' }}
                        </td>
                    </tr>
                    <tr v-if="alertCadence === 'scheduled' && endpointType === 'existing' && selectedEndpoint">
                        <th>Endpoint</th>
                        <td class="small">
                            {{ endpointLabel(endpoints.find(endpoint => endpoint.alert_endpoint_id === selectedEndpoint)) }}
                        </td>
                    </tr>
                    <tr v-if="alertCadence === 'scheduled'">
                        <th>Delivery Limits</th>
                        <td class="small">
                            <div v-for="(day, index) in schedulerData.daysOfWeek" :key="index">
                                <div v-if="schedulerData.schedule.days[day].enabled">
                                    <strong>{{ day }}:</strong>
                                    {{ schedulerData.schedule.days[day].limit !== undefined && schedulerData.schedule.days[day].limit !== null  && schedulerData.schedule.days[day].limit !== '' && !isNaN(schedulerData.schedule.days[day].limit)
                                    ? schedulerData.schedule.days[day].limit
                                    : 'Unlimited' }}
                                </div>
                            </div>
                            <div v-if="schedulerData.schedule.limits.perWeek !== undefined && schedulerData.schedule.limits.perWeek !== null && schedulerData.schedule.limits.perWeek !== '' && !isNaN(schedulerData.schedule.limits.perWeek)">
                                <strong>Per Week:</strong> {{ schedulerData.schedule.limits.perWeek }}
                            </div>
                            <div v-else-if="'perWeek' in schedulerData.schedule.limits">
                                <strong>Per Week:</strong> Unlimited
                            </div>
                            <div v-if="schedulerData.schedule.limits.perMonth !== undefined && schedulerData.schedule.limits.perMonth !== null && schedulerData.schedule.limits.perMonth !== '' && !isNaN(schedulerData.schedule.limits.perMonth)">
                                <strong>Per Month:</strong> {{ schedulerData.schedule.limits.perMonth }}
                            </div>
                            <div v-else-if="'perMonth' in schedulerData.schedule.limits">
                                <strong>Per Month:</strong> Unlimited
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>

</template>

<script>
import { ref, computed, onMounted, watch, reactive } from 'vue'
import axios from 'axios'
import AdvancedScheduler from './AdvancedSchedulerComponent.vue'
import ProgramCriteriaSetComponent from './ProgramCriteriaSetComponent.vue'
import TagSelector from './TagSelectorComponent.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'ProgramCreation',
    components: {
        AdvancedScheduler,
        ProgramCriteriaSetComponent,
        TagSelector,
        vSelect
    },
    props: {
        companies: {
            type: Array,
            required: true,
            default: () => []
        },
        edit: {
            type: Object,
            default: null
        }
    },
    setup(props) {

        const availableInclusionTags = computed(() => {
            return customListNames.value.filter(tag => !suppressedTags.value.includes(tag));
        });

        const availableSuppressionTags = computed(() => {
            return customListNames.value.filter(tag => !selectedTags.value.includes(tag));
        });

        const validationErrors = ref({})
        const schedulerData = reactive({
            daysOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            allDaysLimit: null,
            schedule: {
                days: {
                    Monday: { enabled: true, limit: null },
                    Tuesday: { enabled: true, limit: null },
                    Wednesday: { enabled: true, limit: null },
                    Thursday: { enabled: true, limit: null },
                    Friday: { enabled: true, limit: null },
                    Saturday: { enabled: true, limit: null },
                    Sunday: { enabled: true, limit: null }
                },
                limits: {
                    perWeek: null,
                    perMonth: null
                }
            }
        })
        const criteriaSetChanged = ref(false)
        const criteriaSetSaved = ref(false)
        const criteriaSetData = ref(null)
        const isNewCriteriaSet = ref(false)
        const criteriaSetType = ref('none')

        const criteriaSets = ref([])
        const criteriaSetLoaded = ref(false)

        const getCriteriaSetName = computed(() => (slug) => {
            const criteriaSet = criteriaSets.value.find(set => set.slug === slug)
            return criteriaSet ? criteriaSet.name : 'Unknown Criteria Set'
        })

        const updateScheduleData = (data) => {
            Object.assign(schedulerData, data)
        }

        const isEditMode = computed(() => !!props.edit)
        const currentStep = ref(1)
        const selectedCompany = ref('')
        const consumerSource = ref('')
        const customListType = ref('')
        const selectedCustomList = ref('')
        const newListName = ref('')
        const consumerCount = ref(null)
        const selectedCriteriaSet = ref('')
        const inquiryType = ref('')
        const historicalDays = ref(1)
        const alertCadence = ref('one-time')
        const endpointType = ref('existing')
        const newEndpointName = ref('')
        const newEndpointUrl = ref('')
        const programName = ref('')
        const programDescription = ref('')
        const trackingId = ref('')
        const selectedSubscriberCodeSet = ref(null)
        const termsAgreed = ref(false)
        const fileToUpload = ref(null)

        const endpoints = ref([])
        const selectedEndpoint = ref(null)

        const customLists = ref([])
        const customListNames = computed(() => {
            return customLists.value.map(list => list.name)
        })

        const subscriberCodeSets = ref([])

        const selectedTags = ref([])
        const tagCombinationMethod = ref('merge')
        const suppressedTags = ref([])
        const newListTag = ref('')

        const formattedConsumerCount = computed(() => {
            if(consumerCount.value>0) {
                if (consumerCount.value === 100) {
                    return "<100"
                }
                if (consumerCount.value === 200000000) {
                    return ">200,000,000"
                }
                return consumerCount.value ? consumerCount.value.toLocaleString() : '0'
            }else if(consumerCount.value === -2){
                return 'Calculating...'
            }else{
                return 'Unknown'
            }
        })

        const sanitizeNewListName = () => {
            newListName.value = newListName.value.replace(/[^a-z0-9]/g, '')
        }

        const endpointLabel = (endpoint) => {
            if (!endpoint || typeof endpoint !== 'object') {
                return 'No endpoint selected';
            }
            const name = endpoint.name || 'Unnamed';
            const url = endpoint.url || 'No URL';
            return `${name} (${url})`;
        }

        watch(consumerSource, (newValue) => {
            if (newValue === 'customList') {
                customListType.value = 'existingList'
            }
        })

        watch(inquiryType, (newValue) => {
            if (newValue === 'historical') {
                alertCadence.value = 'one-time'
            } else if (newValue === 'new') {
                alertCadence.value = 'scheduled'
            }
            fetchConsumerCount()
        })

        watch(historicalDays, () => {
            if (inquiryType.value === 'historical') {
                fetchConsumerCount()
            }
        })

        const isStep2Valid = computed(() => {
            if (consumerSource.value === 'prescreen') return true;
            if (consumerSource.value === 'customList') {
                if (customListType.value === 'existingList') {
                    return selectedTags.value.length > 0;
                }
                if (customListType.value === 'uploadNewList') {
                    return !!newListTag.value && !!fileToUpload.value;
                }
            }
            return false;
        })

        const isValidUrl = (url) => {
            try {
                new URL(url);
                return true;
            } catch (error) {
                return false;
            }
        }

        const isNewEndpointValid = computed(() => {
            return !!newEndpointName.value && !!newEndpointUrl.value && isValidUrl(newEndpointUrl.value);
        })

        const isStep3Valid = computed(() => {
            if (criteriaSetType.value === 'none') return true;
            if (criteriaSetType.value === 'use') {
                if (isNewCriteriaSet.value) {
                    return !!selectedCriteriaSet.value && !criteriaSetChanged.value && criteriaSetSaved.value;
                } else {
                    return !!selectedCriteriaSet.value && !criteriaSetChanged.value;
                }
            }
            return false;
        })

        const isStep4Valid = computed(() => {
            if (!inquiryType.value) return false;
            if (inquiryType.value === 'historical' && (historicalDays.value < 1 || historicalDays.value > 60)) return false;
            if (alertCadence.value === 'scheduled') {
                if (endpointType.value === 'existing') {
                    return !!selectedEndpoint.value;
                }
                if (endpointType.value === 'new') {
                    return isNewEndpointValid.value;
                }
            }
            return true;
        })

        const isStep5Valid = computed(() => {
            return programName.value && selectedSubscriberCodeSet.value && termsAgreed.value
        })

        const nextStep = () => {
            if (currentStep.value === 3) {
                criteriaSetData.value = {
                    type: criteriaSetType.value,
                    slug: criteriaSetType.value === 'use' ? selectedCriteriaSet.value : null,
                }
            }
            currentStep.value++
            if (currentStep.value === 4) {
                if (!inquiryType.value) {
                    inquiryType.value = 'none';
                }
            }
            //fetchConsumerCount();
        }

        const previousStep = () => {
            if (currentStep.value > 1) {
                currentStep.value--
                if (currentStep.value === 3 && criteriaSetData.value) {
                    criteriaSetType.value = criteriaSetData.value.type
                    selectedCriteriaSet.value = criteriaSetData.value.slug
                }
            }
        }

        watch([selectedTags, suppressedTags, availableInclusionTags, availableSuppressionTags], ([newSelectedTags, newSuppressedTags, newAvailableInclusionTags, newAvailableSuppressionTags]) => {
            fetchConsumerCount()
        })

        const updateNewListTag = (tags) => {
            newListTag.value = tags[0] || ''
        }

        const saveNewList = async () => {
            if (selectedTags.length === 0 || !fileToUpload.value) {
                console.error('Invalid list data');
                return;
            }

            const formData = new FormData();
            formData.append('tags', selectedTags.value);
            formData.append('csvfile', fileToUpload.value);
            formData.append('company_id', selectedCompany.value);
            formData.append('ignore_redirect', true);

            try {
                const response = await axios.post('/ps/tags', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.status === 200 || response.status === 201) {
                    await fetchCustomLists(selectedCompany.value);

                    customListType.value = 'existingList';
                    newListTag.value = '';
                    fileToUpload.value = null;
                    // Reset file input
                    const fileInput = document.querySelector('input[type="file"]');
                    if (fileInput) fileInput.value = '';

                    fetchConsumerCount();
                }
            } catch (error) {
                console.error('Error saving new list:', error);
                // Handle error (e.g., show error message to user)
            }
        };

        const handleFileUpload = (event) => {
            fileToUpload.value = event.target.files[0];
        }

        const fetchConsumerCount = async () => {

            if(consumerCount.value === -2){
                return;
            }

            try {
                consumerCount.value = -2;

                let programData = {
                    consumer_source: consumerSource.value,
                    triggers_criteria_set_id: criteriaSetType.value === 'use' ? selectedCriteriaSet.value : null,
                    inquiry_type: inquiryType.value,
                    historical_days: inquiryType.value === 'historical' ? historicalDays.value : null,
                }

                if (consumerSource.value === 'customList') {
                    programData = {
                        ...programData,
                        custom_list_type: customListType.value,
                        tags: selectedTags.value,
                        tag_combination_method: tagCombinationMethod.value,
                        tags_suppressed: suppressedTags.value,
                    }
                }

                const response = await axios.post('/ps/get-consumer-count', programData)
                consumerCount.value = response.data.count
            } catch (error) {
                console.error('Error fetching consumer count:', error)
                consumerCount.value = null
            }
        }

        const fetchCriteriaSets = async () => {
            try {
                const response = await axios.get('/ps/get-criteria-for-user')
                criteriaSets.value = response.data
                criteriaSetLoaded.value = true
            } catch (error) {
                console.error('Error fetching criteria sets:', error)
            }
        }

        const handleCriteriaSetChanged = async (changed) => {
            criteriaSetChanged.value = changed
            if (changed) {
                criteriaSetSaved.value = false
            }
        }

        const handleCriteriaSetSaved = async (savedSlug) => {
            fetchCriteriaSets()
            criteriaSetChanged.value = false
            criteriaSetSaved.value = true
            selectedCriteriaSet.value = savedSlug
            initialCriteriaSetSlug.value = savedSlug
            criteriaSetData.value = {
                type: 'use',
                slug: savedSlug,
            }
            await fetchConsumerCount()
        }

        const updateSelectedCriteriaSet = async (newSlug) => {
            selectedCriteriaSet.value = newSlug
            criteriaSetChanged.value = false
            criteriaSetSaved.value = true
            criteriaSetData.value = {
                type: 'use',
                slug: newSlug,
            }
            await fetchConsumerCount()
        }

        const handleCriteriaSetTypeChange = (isNew) => {
            isNewCriteriaSet.value = isNew;
            if (!isNew) {
                criteriaSetSaved.value = true;
            }else{
                selectedCriteriaSet.value = null;
            }
        }

        const fetchSubscriberCodes = async (companySlug) => {
            if (companySlug !== null && companySlug !== "") {
                try {
                    const response = await axios.get(`/get-trigger-subscribercodes-by-company/${companySlug}`)
                    if (response.status === 200) {
                        console.log('Fetched subscriber codes:', response.data);
                        // Convert object to array if necessary
                        if (typeof response.data === 'object' && !Array.isArray(response.data)) {
                            subscriberCodeSets.value = Object.values(response.data)
                        } else if (Array.isArray(response.data)) {
                            subscriberCodeSets.value = response.data
                        } else {
                            console.error('Unexpected data format:', response.data)
                            subscriberCodeSets.value = []
                        }
                        console.log('Updated subscriberCodeSets:', subscriberCodeSets.value);
                    } else {
                        console.error('Error fetching subscriber codes:', response.data)
                        subscriberCodeSets.value = [] // Set to empty array on error
                    }
                } catch (error) {
                    console.error('Error fetching subscriber codes:', error)
                    subscriberCodeSets.value = [] // Set to empty array on error
                }
            }
        }

        const fetchEndpoints = async (companySlug) => {
            if (companySlug !== null && companySlug !== "") {
                try {
                    const response = await axios.get(`/get-trigger-endpoints-by-company/${companySlug}`)
                    if (response.status === 200) {
                        // Convert the object to an array
                        endpoints.value = Object.values(response.data)
                    } else {
                        console.error('Error fetching endpoints:', response.data)
                    }
                } catch (error) {
                    console.error('Error fetching endpoints:', error)
                }
            }
        }

        const fetchCustomLists = async (companySlug) => {
            if (companySlug !== null && companySlug !== "") {
                try {
                    const response = await axios.get(`/ps/tags/c/${companySlug}`)
                    if (response.status === 200) {
                        // Convert object to array if necessary
                        if (typeof response.data === 'object' && !Array.isArray(response.data)) {
                            customLists.value = Object.values(response.data)
                        } else if (Array.isArray(response.data)) {
                            customLists.value = response.data
                        } else {
                            console.error('Unexpected data format:', response.data)
                            customLists.value = []
                        }
                    } else {
                        console.error('Error fetching lists:', response.data)
                        customLists.value = []
                    }
                } catch (error) {
                    console.error('Error fetching lists:', error)
                    customLists.value = []
                }
            } else {
                customLists.value = []
            }
            // Log the result for debugging
            //console.log('customLists:', customLists.value)
        }

        const saveNewEndpoint = async () => {
            if (!isNewEndpointValid.value) {
                console.error('Invalid endpoint data');
                return;
            }

            try {
                const response = await axios.post('/ps/alert-endpoints', {
                    name: newEndpointName.value,
                    url: newEndpointUrl.value,
                    company_id: selectedCompany.value,
                    ignore_redirect: true
                });

                if (response.status === 201) {
                    await fetchEndpoints(selectedCompany.value);
                    selectedEndpoint.value = response.data.alert_endpoint_id;
                    endpointType.value = 'existing';
                    newEndpointName.value = '';
                    newEndpointUrl.value = '';
                }
            } catch (error) {
                console.error('Error saving new endpoint:', error);
                // Handle error (e.g., show error message to user)
            }
        }

        const submitProgram = async () => {
            try {
                validationErrors.value = {}
                let programData = {
                    company_id: selectedCompany.value,
                    consumer_source: consumerSource.value,
                    triggers_criteria_set_id: criteriaSetType.value === 'use' ? selectedCriteriaSet.value : null,
                    inquiry_type: inquiryType.value,
                    historical_days: inquiryType.value === 'historical' ? historicalDays.value : null,
                    alert_cadence: alertCadence.value,
                    endpoint_type: alertCadence.value === 'scheduled' ? endpointType.value : null,
                    endpoint_id: alertCadence.value === 'scheduled' ? (endpointType.value === 'existing' ? selectedEndpoint.value : null) : null,
                    new_endpoint_name: alertCadence.value === 'scheduled' && endpointType.value === 'new' ? newEndpointName.value : null,
                    name: programName.value,
                    description: programDescription.value,
                    tracking_id: trackingId.value,
                    triggers_subscriber_code_id: selectedSubscriberCodeSet.value,
                    schedule: JSON.stringify(schedulerData),
                }

                if (consumerSource.value === 'customList') {
                    programData = {
                        ...programData,
                        custom_list_type: customListType.value,
                        tags: selectedTags.value,
                        tag_combination_method: tagCombinationMethod.value,
                        tags_suppressed: suppressedTags.value,
                    }
                }

                let response
                if (isEditMode.value) {
                    response = await axios.put(`/ps/program/${props.edit.id}`, programData)
                } else {
                    response = await axios.post('/ps/programs', programData)
                }

                window.location.href = "/ps/program/" + response.data.id;

            } catch (error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    validationErrors.value = error.response.data.errors
                    console.error('Validation errors:', validationErrors.value)
                } else {
                    console.error('Error submitting program:', error)
                }
            }
        }

        watch(selectedCompany, (newValue) => {
            if (newValue) {
                fetchSubscriberCodes(newValue)
                fetchEndpoints(newValue)
                fetchCustomLists(newValue)
            }
        })

        watch(consumerSource, (newValue) => {
            if (newValue === 'prescreen') {
                criteriaSetType.value = 'use'
            }
            fetchConsumerCount()
            if (newValue) {
                fetchSubscriberCodes(selectedCompany.value)
                fetchEndpoints(selectedCompany.value)
                fetchCustomLists(selectedCompany.value)
            }
        })

        watch(selectedTags, () => {
            if (consumerSource.value === 'customList' && customListType.value === 'existingList') {
                fetchConsumerCount()
            }
        })

        watch(tagCombinationMethod, () => {
            if (consumerSource.value === 'customList' && customListType.value === 'existingList') {
                fetchConsumerCount()
            }
        })

        watch(suppressedTags, () => {
            if (consumerSource.value === 'customList' && customListType.value === 'existingList') {
                fetchConsumerCount()
            }
        })

        watch(customListType, () => {
            if (consumerSource.value === 'customList') {
                fetchConsumerCount()
            }
        })

        watch(criteriaSetType, (newValue) => {
            fetchConsumerCount()
        })

        const initializeEditMode = async () => {
            if (isEditMode.value) {
                const program = props.edit;

                await Promise.all([
                    fetchCriteriaSets(),
                    fetchSubscriberCodes(program.company_id),
                    fetchEndpoints(program.company_id),
                    program.tags.length > 0 ? fetchCustomLists(program.company_id) : Promise.resolve()
                ]);

                // Step 1: Company Selection
                selectedCompany.value = program.company_id;

                // Step 2: Consumer Source
                consumerSource.value = program.tags.length ? 'customList' : 'prescreen';
                if (consumerSource.value === 'customList') {
                    customListType.value = 'existingList';
                    selectedTags.value = program.tags || [];
                    tagCombinationMethod.value = program.tag_combination_method || 'merge';
                    suppressedTags.value = program.tags_suppressed || [];
                }

                // Step 3: Criteria Set
                if (program.criteria_set_id) {
                    criteriaSetType.value = 'use';
                    selectedCriteriaSet.value = program.criteria_set_id;

                    // Force the ProgramCriteriaSetComponent to reload
                    await nextTick();
                    const criteriaSetComponent = document.querySelector('.criteria-set-component');
                    if (criteriaSetComponent && criteriaSetComponent.__vue__) {
                        await criteriaSetComponent.__vue__.loadCriteriaSet();
                    }
                } else {
                    criteriaSetType.value = 'none';
                }

                // Step 4: Program Options
                if (program.inquiries_historical_days) {
                    inquiryType.value = 'historical';
                    historicalDays.value = program.inquiries_historical_days;
                } else if (program.inquiries_monitor) {
                    inquiryType.value = 'new';
                } else {
                    inquiryType.value = 'none';
                }

                alertCadence.value = program.endpoint_id ? 'scheduled' : 'one-time';
                if (alertCadence.value === 'scheduled') {
                    endpointType.value = 'existing';
                    selectedEndpoint.value = program.endpoint_id;
                }

                if (program.schedule) {
                    try {
                        const parsedSchedule = JSON.parse(program.schedule);
                        Object.assign(schedulerData, parsedSchedule);
                    } catch (error) {
                        console.error('Error parsing schedule:', error);
                    }
                }

                // Step 5: Program Details
                programName.value = program.name;
                programDescription.value = program.description || '';
                trackingId.value = program.tracking_id || '';
                selectedSubscriberCodeSet.value = program.subscriber_code_id;

                // Set the current step where to start editing
                currentStep.value = 2;
            }
        };

        onMounted(() => {
            if (isEditMode.value) {
                initializeEditMode();
            } else {
                loadInitialData();
            }
        });

        const loadInitialData = async () => {
            if (props.companies.length === 1) {
                selectedCompany.value = props.companies[0].slug
                currentStep.value = isEditMode.value ? 5 : 2
            }
            await fetchSubscriberCodes(selectedCompany.value)
            await fetchEndpoints(selectedCompany.value)
            await fetchCriteriaSets()
            await fetchCustomLists(selectedCompany.value);
        }

        return {
            currentStep,
            selectedCompany,
            consumerSource,
            customListType,
            selectedTags,
            tagCombinationMethod,
            suppressedTags,
            newListTag,
            consumerCount,
            formattedConsumerCount,
            selectedCriteriaSet,
            inquiryType,
            historicalDays,
            alertCadence,
            endpointType,
            newEndpointName,
            newEndpointUrl,
            programName,
            programDescription,
            trackingId,
            selectedSubscriberCodeSet,
            termsAgreed,
            companies: props.companies,
            customLists,
            subscriberCodeSets,
            isStep2Valid,
            isStep3Valid,
            isStep4Valid,
            isStep5Valid,
            nextStep,
            previousStep,
            handleFileUpload,
            fetchConsumerCount,
            validationErrors,
            submitProgram,
            schedulerData,
            updateScheduleData,
            criteriaSetChanged,
            criteriaSetSaved,
            handleCriteriaSetChanged,
            handleCriteriaSetSaved,
            updateSelectedCriteriaSet,
            handleCriteriaSetTypeChange,
            criteriaSetData,
            criteriaSetType,
            endpoints,
            selectedEndpoint,
            endpointLabel,
            saveNewEndpoint,
            isValidUrl,
            isNewEndpointValid,
            getCriteriaSetName,
            isEditMode,
            initializeEditMode,
            loadInitialData,
            saveNewList,
            fileToUpload,
            sanitizeNewListName,
            updateNewListTag,
            customListNames,
            availableInclusionTags,
            availableSuppressionTags,
        }
    }
}
</script>
