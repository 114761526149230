<template>
    <div class="card-body">

        <div class="form-group">
            <label for="name">Criteria Set Name</label>
            <p>{{ criteriaSet.name }}</p>
        </div>

        <!-- Criteria Configs -->
        <label>Criteria Configurations</label>

        <table class="table table-bordered table-striped">
            <thead>
            <tr>
                <th>Attribute</th>
                <th>Rule</th>
                <th>Value</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(config, index) in criteriaConfigs" :key="index">
                <td>{{ getAttributeName(config.attribute) }}</td>
                <td>{{ getRuleName(config.rule) }}</td>
                <td>{{ config.value }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        criteriaConfigData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            attributes: [],
            rules: [
                { value: '==', name: 'Equals' },
                { value: '!=', name: 'Does Not Equal' },
                { value: '>', name: 'Greater Than' },
                { value: '<', name: 'Less Than' },
                { value: '>=', name: 'Greater Than or Equal To' },
                { value: '<=', name: 'Less Than or Equal To' },
            ],
            criteriaSet: {
                name: this.criteriaConfigData.name,
            },
            criteriaConfigs: this.criteriaConfigData.criteria_configs,
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        // Add only the methods needed for view-only functionality

        fetchAttributes() {
            axios.get('/get-attributes-for-user')
                .then(response => {
                    this.attributes = response.data;
                })
                .catch(error => {
                    console.error('Error fetching attributes:', error);
                });
        },
        getAttributeName(attributeSlug) {
            const attribute = this.attributes.find(attr => attr.slug === attributeSlug);
            return attribute ? attribute.name : '';
        },
        getRuleName(ruleValue) {
            const rule = this.rules.find(rule => rule.value === ruleValue);
            return rule ? rule.name : '';
        },
    },
};
</script>
