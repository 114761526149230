<template>
    <div class="tag-selector">
        <div class="mb-2">
      <span v-for="tag in internalTags" :key="tag" class="badge bg-secondary me-2 mb-2">
        {{ tag }}
        <button @click="removeTag(tag)" class="btn-close btn-close-white ms-1" aria-label="Remove"></button>
      </span>
        </div>
        <div class="input-group">
            <input
                v-model="newTag"
                @input="updateSuggestions"
                @keydown.enter.prevent="addTag"
                type="text"
                class="form-control"
                :placeholder="inputPlaceholder"
                aria-label="Add tags"
            >
            <button v-if="showAdd" @click="addTag" class="btn btn-outline-secondary" type="button">Add</button>
        </div>
        <div v-if="showAdd" class="small mb-3 mt-2">Only numbers and lowercase letters are allowed in a tag name.</div>

        <div v-if="suggestions.length" class="mt-2">
            <span class="pe-2">Search results:</span>
            <button
                v-for="suggestion in suggestions"
                :key="suggestion"
                @click="selectSuggestion(suggestion)"
                class="btn btn-outline-primary btn-sm me-2 mb-2"
                :disabled="disabledTags.includes(suggestion)"
            >
                {{ suggestion }}
            </button>
        </div>

        <div v-if="availableNewestTags.length && !suggestions.length" class="mt-2">
            <span class="pe-2">Suggested tags:</span>
            <button
                v-for="tag in availableNewestTags"
                :key="tag"
                @click="selectSuggestion(tag)"
                class="btn btn-outline-primary btn-sm me-2 mb-2"
                :disabled="disabledTags.includes(tag)"
            >
                {{ tag }}
            </button>
        </div>

        <input type="hidden" name="tags" :value="selectedTagsString">
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';

export default {
    name: 'TagSelectorComponent',
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        companies: {
            type: Array,
            default: () => []
        },
        existingTags: {
            type: Array,
            default: () => []
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        disabledTags: {
            type: Array,
            default: () => []
        }
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const internalTags = ref(props.modelValue);
        const newTag = ref('');
        const suggestions = ref([]);

        const inputPlaceholder = computed(() => {
            return props.showAdd ? "Search for or add new tags..." : "Search for tags...";
        });

        const selectedTagsString = computed(() => internalTags.value.join(', '));

        const availableNewestTags = computed(() => {
            return props.existingTags
                .filter(tag => !internalTags.value.includes(tag) && !props.disabledTags.includes(tag))
                .slice(-8);
        });

        const isValidTag = (tag) => /^[a-z0-9]+$/.test(tag);

        const addTag = () => {
            if (!props.showAdd) return;

            const trimmedTag = newTag.value.trim().toLowerCase();
            if (trimmedTag && isValidTag(trimmedTag) && !internalTags.value.includes(trimmedTag) && !props.disabledTags.includes(trimmedTag)) {
                internalTags.value.push(trimmedTag);
                emit('update:modelValue', internalTags.value);
                newTag.value = '';
                updateSuggestions();
            } else if (!isValidTag(trimmedTag)) {
                alert('Tags can only contain lowercase letters and numbers.');
            } else if (props.disabledTags.includes(trimmedTag)) {
                alert('This tag is already in use and cannot be added.');
            }
        };

        const removeTag = (tag) => {
            internalTags.value = internalTags.value.filter(t => t !== tag);
            emit('update:modelValue', internalTags.value);
        };

        const updateSuggestions = () => {
            if (newTag.value && isValidTag(newTag.value)) {
                suggestions.value = props.existingTags.filter(tag =>
                    tag.toLowerCase().startsWith(newTag.value.toLowerCase()) &&
                    !internalTags.value.includes(tag) &&
                    !props.disabledTags.includes(tag) &&
                    isValidTag(tag)
                );
            } else {
                suggestions.value = [];
            }
        };

        const selectSuggestion = (tag) => {
            if (!internalTags.value.includes(tag) && !props.disabledTags.includes(tag)) {
                internalTags.value.push(tag);
                emit('update:modelValue', internalTags.value);
            }
            newTag.value = '';
            suggestions.value = [];
        };

        watch(newTag, (value) => {
            newTag.value = value.replace(/[^a-z0-9]/g, '').toLowerCase();
        });

        watch(() => props.modelValue, (newValue) => {
            internalTags.value = newValue;
        }, { deep: true });

        return {
            internalTags,
            selectedTagsString,
            newTag,
            suggestions,
            availableNewestTags,
            addTag,
            removeTag,
            updateSuggestions,
            selectSuggestion,
            inputPlaceholder
        };
    }
};
</script>
