<template>
  <div>
    <div class="border border-dark p-2 mb-1">
            <span v-show="display">
                {{ token }}
            </span>
      <span v-show="!display">
                {{ redacted }}
            </span>
      <button v-clipboard:copy="token" id="copy-button" class="btn float-end py-0 px-1 border-0 bg-light"><i class="fas fa-clipboard"></i></button>
    </div>
    <button @click="toggle" class="btn btn-primary mb-2">{{ text }} API Token</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      redacted: null,
      display: false,
      text: "Show"
    }
  },
  props: ['token'],
  created() {
    this.redacted = this.token.replace(/./g, '*');
  },
  methods: {
    toggle() {
      this.display = !this.display;
      if (this.display) {
        this.text = "Hide";
      }
      else {
        this.text = "Show";
      }
    },
  }
}
</script>

<style scoped>

</style>
