<template>
    <div v-if="success" class="alert alert-success" role="alert">
        <span>{{ message }}</span>
    </div>

    <div id="company" class="form-group" v-if="can_toggle_company">

        <label for="company">Company</label>
        <select class="form-select" id="company_slug" name="company_slug" v-model="fields.company_slug" @change="loadSubscribercodes()">
            <option value="" :selected="scenario.company_slug === ''">No Company (System Scenario)</option>
            <option v-for="company in companies" :key="company.slug" :value="company.slug" :selected="company.slug === scenario.company_slug">
                {{ company.name }}
            </option>
        </select>

        <div v-if="errors && errors.company_slug" class="text-danger">{{ errors.company_slug[0] }}</div>
    </div>
    <div id="subscribercode" class="form-group">
        <label for="subscribercode" required>Subscriber Code</label>
        <select class="form-select" id="subscribercode_slug" name="subscribercode_slug" v-model="fields.subscriber_code" required>
            <option value="" disabled>Select a Subscriber Code</option>
            <option value="default" :selected="scenario.subscriber_code === ''">Default</option>
            <option v-for="subscribercode in subscribercodes" :key="subscribercode.slug" :value="subscribercode.slug" :selected="subscribercode.slug === scenario.subscriber_code">
                {{ subscribercode.name }}
            </option>
        </select>
        <div v-if="errors && errors.subscriber_code" class="text-danger">{{ errors.subscriber_code[0] }}</div>
    </div>
</template>

<script>
import i18next from "i18next";

export default {
    data() {
        return {
            loaded: true,
            success: false,
            subscribercodes: {},
            errors: {},
            fields: {},
            message: '',
            urlParams: null
        }
    },
    props: [
        'companies',
        'can_toggle_company',
        'scenario',
    ],
    mounted() {
        this.fields = this.scenario;
        if(this.fields.company_slug) {
            this.loadSubscribercodes();
        }
    },
    created() {
        if (!this.can_toggle_company) {
            // This is either a subscribercode manager or company manager there is only one company
            this.fields.company_slug = this.companies[0].slug;
            console.log(this.fields.company_slug);
            if (!this.can_toggle_subscribercode) {
                // This is is subscribercode manager
                this.loadBranch();
            }
        }
        let params = new URLSearchParams(window.location.search);
        this.urlParams = { 'c': params.get('c'), 'b': params.get('b')};
        if (this.urlParams.c) {
            this.fields.company_slug = this.urlParams.c;
        }
    },
    methods: {
        loadSubscribercodes: function () {

            if(this.fields.company_slug) {
                axios.get('/get-subscribercodes-by-company/' + this.fields.company_slug)
                    .then(response => {
                        this.subscribercodes = response.data;
                        // if (this.urlParams.c == this.fields.company_slug && this.urlParams.b) {
                        //     this.fields.subscribercode_slug = this.urlParams.b;
                        // }
                    }).catch(error => {
                    // console.log(error);
                });
            }else{
                this.subscribercodes = {};
            }
        },
    }
}
</script>
